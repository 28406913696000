import { create } from './create'
import { del } from './delete'
import { getList } from './getList'
import { getOne } from './getOne'
import { update } from './update'
import { Headers, makeHeaders } from './utils'

interface Params {
  id?: string,
  filter: any,
  pagination: any,
  sort: any
}

let headers: Headers = {}

const gildDataProvider = (token?: string) => {
  headers = makeHeaders(token)

  return {
    getList: getList(headers),
    getOne: getOne(headers),
    getMany: (resource: string, params: Params) => {

    },
    getManyReference: (resource: string, params: Params) => {

    },
    create: create(headers),
    update: update(headers),
    updateMany: (resource: string, params: Params) => {

    },
    delete: del(headers),
    deleteMany: (resource: string, params: Params) => {

    }
  }
};

export default gildDataProvider