import React from 'react'
import { 
  List, Datagrid, TextField, BooleanInput, Filter,
  SimpleShowLayout, TextInput, ArrayField, Show, TopToolbar, SimpleForm
} from 'react-admin'
import { withRouter } from 'react-router'
import { ExportDataButton, GoToUser, UploadFileButton } from './Buttons'
import { makeUrl, replaceUrlParams } from './DataProvider/utils'
import { env } from './env'
import { DateTimeField } from './Fields'
import Pagination from './Pagination'

const { attachPolicyUrl, attachQuoteUrl, facadeBaseUrl, getSession } = env


const SearchFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Session" source="session" alwaysOn />
    <BooleanInput label="Received Quote" source="quote" alwaysOn />
    <BooleanInput label="Purchased Policy" source="bind" alwaysOn />
    <BooleanInput label="Waiting External Flows" source="external" alwaysOn />
  </Filter>
)

const SessionToolbar = withRouter((props: any) => {
  const { data = {}, history } = props
  return (
    <TopToolbar>
      {(data?.uid === '_anon__' || data?.uid === '__anon__') ? (
        <p style={{cursor: 'not-allowed'}}>Anonymous User</p>
      ) : (
        <GoToUser history={history} uid={data?.uid} />
      )}
    </TopToolbar>
  )
})

const TextOrUrlField = (answer: any) => {
    if (answer.record.key === 'ICON_access_url') {
        const url = answer.record.answer
        return (<a href={url}>{url}</a>)
    }
    return (<TextField source="answer" />)
}

export const SessionEdit = (props: any) => {
  const { id } = props
  const uid = id.split('_')[0]
  
  const sessionToCSV = (resp: any) => { 
    return resp['data'].map((r: any) => {
      if (r['key'] == '_sparse-index') {
        return
      }
      r['answer'] = r['answer_human'] || r['answer']
      r['question'] = (r['question'] || {})['message']
      r['ts'] = new Date(r['ts']).toLocaleString()
      return r
    }).filter((r: any) => !!r)
  }

  const policyUrl = replaceUrlParams(makeUrl(facadeBaseUrl, attachPolicyUrl), { username: uid })
  const quoteUrl = replaceUrlParams(makeUrl(facadeBaseUrl, attachQuoteUrl), { username: uid })
  const sessionUrl = replaceUrlParams(makeUrl(facadeBaseUrl, getSession), { session: id })

  return (
    <Show {...props} actions={<SessionToolbar />}>
      <SimpleShowLayout>
        {/* <SimpleForm>
          <UploadFileButton label="Attach Quote" session={id} upload_url={quoteUrl} user_id={uid} />
          <UploadFileButton label="Attach Policy" session={id} upload_url={policyUrl} user_id={uid} />
        </SimpleForm> */}
        <ExportDataButton getDataFn={sessionToCSV} fields={['question', 'key', 'answer', 'ts']} name={`session.${id}`} resourceUrl={sessionUrl} />
        <TextField source="session" />
        <ArrayField source="answers">
          <Datagrid>
            <TextField source="question.message" label="Question" />
            <TextField source="key" />
            <TextOrUrlField source="answer" />
            <DateTimeField source="ts" label="Timestamp" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}

export const SessionsList = (props: any) => {
  return (
    <List {...props} filters={<SearchFilter />} pagination={<Pagination />} perPage={25} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="session" />
        <DateTimeField source="ts" label="Timestamp" />
        <TextField source="answer" label="Carrier" />
        <TextField source="name" label="Gilder" />
        <TextField source="email" />
      </Datagrid>
    </List>
  )
}
