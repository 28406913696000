import React from 'react'
import dayjs from 'dayjs'
import { env } from './env'

interface FieldParams {
  source: string
  record?: any
  label?: string
}

const { adminUserGroup, agentUserGroup, csrUserGroup } = env

export const AgentGroupField = ({ source, record = {} }: FieldParams) => {
  let label
  if ((record['groups'] || [])[0]) {
    switch (record['groups'][0]['GroupName']) {
      case adminUserGroup:
        label = 'Admin'
        break
      case agentUserGroup:
        label = 'Agent'
        break
      case csrUserGroup:
        label = 'Customer Service'
        break
    }
  }

  return (
    <span>{label}</span>
  )
}

export const CarrierField = ({ source, record = {}}: FieldParams) => {
  const carriers: any = {
    attune: 'Attune',
    cf: 'Crum & Forster',
    coterie: 'Coterie',
    coverwallet: 'Coverwallet',
    hartford: 'Hartford',
    hiscox: 'Hiscox'
  }

  if (record['carrier']) {
    return <span>{carriers[record.carrier.trim()]}</span>  
  }
  return <span></span>
}

export const DateTimeField = ({ source, record = {} }: FieldParams) => {
  if (!record) {
    return <span></span>
  }
  const ts = dayjs.unix(record[source] / 1000)

  return (
    <span>{ts.format('MM/DD/YYYY h:mm:ss A')}</span>
  )
}

export const JSONEventField = ({ source, record = {}}: FieldParams) => {
  const jsonObj = record[source]
  let useObj = jsonObj
  if (jsonObj['userData']) {
    useObj = jsonObj['userData']
  } else if (jsonObj['login']) {
    useObj = jsonObj['login']
  }

  return (
    <>
      {Object.keys(useObj).map(key => {
        return (
          <span key={key}>{key}: {JSON.stringify(useObj[key])}<br /></span>
        )
      })}
    </>
  )
}
