import React, { createElement, useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { MenuItemLink, getResources, Responsive } from 'react-admin'
import { withRouter } from 'react-router-dom'
import Auth from '@aws-amplify/auth'

import Badge from '@material-ui/core/Badge'
import DashboardIcon from '@material-ui/icons/Dashboard'

import { Headers, makeHeaders } from './DataProvider/utils'
import { env } from './env'


const checkForSessionNotifications = (accessJwt: any, setValue: Function) => {  
    const headers: Headers = makeHeaders(accessJwt)
    const url = `${env.facadeBaseUrl}/${env.getAllSessions}?filter=%7B%22external%22%3Atrue%7D`  // TODO this mimics react-admins URI encoding, but I was having trouble replicating it with methods
    axios.get(url, { headers })
        .then(res => {
            const data = res['data'] || []
            setValue(data['data'].length)
        })
        .catch(err => console.error(err))
}
  
let notificationsInterval: any
const setupNotificationsInterval = (callback: Function) => {
    if (!notificationsInterval) {
        notificationsInterval = setInterval(() => callback(), 60000 * 5)  // refresh every 5 minutes
        callback()
    }
  }

const Menu = ({ resources, onMenuClick, logout }: any) => {
    const [count, setCount] = useState(0)
    useEffect(() => {
        Auth.currentSession().then((resp: any) => {
            const accessJwt = resp.getAccessToken().getJwtToken()
    
            setupNotificationsInterval(() => {
                checkForSessionNotifications(accessJwt, (number: number) => {
                    setCount(number)
                })
            })
        })
    })

    return (
        <div className="backoffice-menu">
            <MenuItemLink
                key="Dashboard"
                to="/"
                primaryText="Dashboard"
                leftIcon={<DashboardIcon />}
                onClick={onMenuClick}
            />
            {resources.map((resource: any) => {
                let primaryText = resource.options && resource.options.label || resource.name
                if (resource.name === 'sessions') {
                    primaryText = (
                        count ? 
                            <Badge badgeContent={count} color="primary">
                                Sessions
                            </Badge> :
                        <span>Sessions</span>
                    )
                }
                return (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={primaryText}
                        leftIcon={createElement(resource.icon)}
                        onClick={onMenuClick}
                    />
                )
            })}
            <Responsive
                small={logout}
                medium={null} // Pass null to render nothing on larger devices
            />
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        notifications: state.notifications,
        resources: getResources(state),
    }
}

export default withRouter(connect(mapStateToProps)(Menu))
