import React from 'react'
import Button from '@material-ui/core/Button'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Toolbar from '@material-ui/core/Toolbar'

import { connect } from 'react-redux'


interface Props {
  nextToken?: string
  page?: number
  setPage?: any
}

class DynamoPagination extends React.Component<Props> {
  render() {
    const { nextToken, page = 1, setPage } = this.props
    if (page === 1 && !nextToken) {
      return null
    }

    return (
      <Toolbar>
        {page > 1 && (
          <Button
            color='primary'
            key='prev'
            startIcon={<ChevronLeft />}
            onClick={() => setPage(page - 1)}>
            Prev
          </Button>
        )}
        {nextToken && (
          <Button
            color='primary'
            key='next'
            endIcon={<ChevronRight />}
            onClick={() => setPage(page + 1)}>
            Next
          </Button>
        )}
      </Toolbar>
    )
  }
}

const mapStateToProps = (state: any) => ({ nextToken: state.nextToken })

export default connect(mapStateToProps)(DynamoPagination)