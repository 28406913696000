import axios from 'axios'
import { env, EnvType } from '../env'
import { Headers, makeUrl, Params, replaceUrlParams } from './utils'

const {
  facadeBaseUrl, getUser, getMember, getSession, getPolicy, getQuote,
  getSessionByUsername, getEventsByUsername, getQuotesByUsername, getPoliciesByUsername
}: EnvType = env


export const getOne = (headers: Headers) => 
  (resource: string, params: Params) => {
    const { id } = params
    
    let handleResp = (resp: any) => {
      return {
        data: resp.data['data']
      }
    }
    let url = ''

    switch (resource) {
      case 'employees': {
        url = `${facadeBaseUrl}/${replaceUrlParams(getUser, { username: id })}`;
        handleResp = (resp: any) => {
            // Make sure that employees has the id it's looking for
            resp.data['data']['id'] = resp.data['data']['Username']
            return {
                data: resp.data['data']
            }
        }
        break;
      }
      case 'gilders': {
        const getEventsUrl = makeUrl(facadeBaseUrl, `${getEventsByUsername}?user_id=${id}`)
        const getMemberUrl = makeUrl(facadeBaseUrl, replaceUrlParams(getMember, { username: id }))
        const getSessionsUrl = makeUrl(facadeBaseUrl, replaceUrlParams(getSessionByUsername, { username: id }))
        const getPoliciesUrl = makeUrl(facadeBaseUrl, replaceUrlParams(getPoliciesByUsername, { username: id }))
        const getQuotesUrl = makeUrl(facadeBaseUrl, replaceUrlParams(getQuotesByUsername, { username: id }))

        /* TODO Kind of goofy response destructuring,
          but will have to re-examine all endpoints to standardize */
        const returnData = (resp: any) => {
          return resp.data['data']
        }

        const returnEventsData = (resp: any) => {
          return resp.data
        }

        const returnSessionData = (resp: any) => {
          const { data } = resp.data
          data.sort((a: any, b: any) => a['ts'] - b['ts'])
          return data.reverse()  // Return in DESC order
        }
  
        return Promise.allSettled([
          axios.get(getEventsUrl, { headers })
            .then(returnEventsData),
          axios.get(getMemberUrl, { headers })
            .then(returnData),
          axios.get(getPoliciesUrl, { headers })
            .then(returnData),
          axios.get(getQuotesUrl, { headers })
            .then(returnData),
          axios.get(getSessionsUrl, { headers })
            .then(returnSessionData)
        ]).then((result: any[]) => {
          const [events, member, policies, quotes, sessions] = result
          const d = {
            data: {
              ...member.value,
              id: member.value['sub'],
              sessions: sessions.value,
              events: events.value,
              policies: policies.value,
              quotes: quotes.value
            }
          }
          console.log('data', d)
          return d
        })
      }
      case 'policies': {
        url = makeUrl(facadeBaseUrl, replaceUrlParams(getPolicy, { file_id: id }))
        break;
      }
      case 'quotes': {
        url = makeUrl(facadeBaseUrl, replaceUrlParams(getQuote, { file_id: id }))
        handleResp = (resp: any) => {
            console.log('quotes.resp', resp)
            // Yearly Premium's are stored in cents. Need to display as dollars
            const yearlyPremium = resp.data.data.yearly_premium
            if(yearlyPremium) {
                const yearlyPremiumInCents = resp.data.data.yearly_premium / 100
                resp.data.data.yearly_premium = yearlyPremiumInCents.toString()
            }
            return resp.data
        }
        break;
      }
      case 'partners':
      case 'sessions': {
        url = makeUrl(facadeBaseUrl, replaceUrlParams(getSession, { session: id }))
        handleResp = (resp: any) => {
          const data = resp.data['data']
          const start = data.find((r: any) => r['session-start'] === 1)
          const answers = data.filter((r: any) => r['key'] !== '_sparse-index')
          return {
            data: {
              id: start['uid'] !== '__anon__' ? `${start['uid']}_${start['session']}` : `_${start['session']}`,
              answers,
              ...start
            }
          }
        }
        break;
      }
    }

    return axios.get(url, { headers })
      .then(handleResp)
      .catch(res => {
        console.error('Error in axios request')
        console.log(res)
      })
  }