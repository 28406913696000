import axios from 'axios'
import { env, EnvType } from '../env'
import { Headers, makeUrl, UpdateParams, replaceUrlParams } from './utils'

const { facadeBaseUrl, getUser, getMember, getSession, getSessionByUsername, getEventsByUsername }: EnvType = env

interface Attribute {
  Name: string
  Value: string
}


const buildAttributes = (params: any) => {
  const attributes: Attribute[] = []
  const attributeKeys = (params['data']['UserAttributes'] || []).map((a: any) => a['Name'])
  attributeKeys.forEach((key: string) => {
    const skip = ['sub']
    if (skip.indexOf(key) < 0) {
      attributes.push({
        Name: key,
        Value: params['data'][key]
      })
    }
  })
  const givenName = params['data']['given_name'] || ''
  const familyName = params['data']['family_name'] || ''
  attributes.push({
    Name: 'name',
    Value: `${givenName} ${familyName}`
  })

  return attributes
}

export const update = (headers: Headers) => 
  (resource: string, params: UpdateParams) => {
    const { id } = params
    
    let handleResp = (resp: any) => {
      console.log(resp)
      return resp
    //   return {
    //     data: resp.data['data']
    //   }
    }
    let url = '';
    let content: any = {}

    switch (resource) {
      case 'employees': {
        url = `${facadeBaseUrl}/${replaceUrlParams(getUser, { username: id })}`
        const enabled = params['data']['Enabled']
        content['attributes'] = buildAttributes(params)
        content['enabled'] = enabled
        break
      }
      case 'gilders': {
        url = `${facadeBaseUrl}/${replaceUrlParams(getMember, { username: id })}`
        const enabled = params['data']['Enabled']
        content['attributes'] = buildAttributes(params)
        content['enabled'] = enabled
        break
      }
    }

    console.log(url, headers, content)
    return axios.patch(url, content, { headers })
      .then(handleResp)
      .catch(res => console.error(res))
  }
