import React from 'react'
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EmailField,
  Filter,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  required
} from 'react-admin'

import { Typography } from '@material-ui/core'

import { makeUrl, replaceUrlParams } from './DataProvider/utils'
import { ResetPasswordButton } from './Buttons'
import { EnvType, env } from './env'
import { AgentGroupField } from './Fields'

const { facadeBaseUrl, resetBackofficeUserPassword }: EnvType = env

const AgentEditActions = ({ basePath, data, resource, resetPasswordUrl }: any) => {
  let username
  if (data) {
    username = data['Username']
  }
  return (
    <TopToolbar>
      <ResetPasswordButton url={replaceUrlParams(resetPasswordUrl, { username })} />
    </TopToolbar>
  )
}

const SearchFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Name" source="name"  />
  </Filter>
)


const Aside = () => (
  <div style={{ width: 200, margin: '1em' }}>
    <Typography variant="h6">Agent Accounts</Typography>
    <Typography variant="body2">
      This will create a new user account for the Gild backoffice. After creation, the user
      will be sent an email with a link and instructions on how to verify their account and login.
    </Typography>
    <Typography variant="h6">User Groups</Typography>
    <Typography variant="body2">
      There are three types of Agent users, <strong>Admins</strong>, <strong>Agents</strong>, and <strong>Customer Service</strong>.
      All will have access to the Gild Backoffice, however, only Admins have the ability to create
      and edit other Agent users.
    </Typography>
  </div>
)


export const AgentCreate = (props: any) => (
  <Create {...props} aside={<Aside />}>
    <SimpleForm>
      <TextInput source="given_name" />
      <TextInput source="family_name" />
      <TextInput source="email" fullWidth={true} validate={required()} />
      <TextInput source="phone_number" fullWidth={true} />
      <SelectInput source="group" choices={[
        { id: 'admin', name: 'Admin' },
        { id: 'agent', name: 'Agent' },
        { id: 'csr', name: 'Customer Service' },
      ]} validate={required()} />
    </SimpleForm>
  </Create>
)

export const AgentList = (props: any) => (
  <List {...props} filters={<SearchFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <EmailField source="email" />
      <BooleanField source="email_verified" />
      <TextField source="phone_number" />
      <BooleanField source="phone_number_verified" />
      <AgentGroupField source="groups[0].GroupName" label="Role" />
      <DateField source="UserCreateDate" label="User Since" />
      <TextField source="id" />
    </Datagrid>
  </List>
);


export const AgentEdit = (props: any) => {
  const resetCustomerUserPasswordUrl = makeUrl(facadeBaseUrl, resetBackofficeUserPassword)
  return (
    <Edit {...props} actions={<AgentEditActions resetPasswordUrl={resetCustomerUserPasswordUrl} />}>
      <SimpleForm>
        <BooleanInput source="Enabled" />
        <TextInput disabled source="Username" />
        <TextInput disabled source="UserStatus" />
        <TextInput source="email" fullWidth={true} />
        <BooleanInput source="email_verified" />
        <TextInput source="phone_number" fullWidth={true} />
        <BooleanInput source="phone_number_verified" />
        <ArrayField source="groups">
          <Datagrid>
            <TextField source="GroupName" fullWidth={true} />
            <TextField source="Description" fullWidth={true} />
          </Datagrid>
        </ArrayField>
        <DateInput disabled source="UserCreateDate" />
        <DateInput disabled source="UserLastModifiedDate" />
        <TextInput disabled source="id" />
      </SimpleForm>
    </Edit>
  )
}
