import React from 'react'
import { withRouter } from 'react-router'
import { Button, Card, CardContent } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import packageJson from '../package.json'
import { env } from './env'

const envAbbrev: any = {
  dev: 'd',
  staging: 's',
  prod: 'p'
}

const GildButton = withStyles((theme) => ({
  root: {
    borderRadius: '20px',
    color: '#fff',
    padding: '8px 15px',
    textTransform: 'capitalize'
  },
}))(Button)

const GoToMembers = withRouter(({ history }) => (
  <GildButton
    color='primary'
    variant='contained'
    onClick={() => history.push('gilders')}
    endIcon={<ChevronRightRoundedIcon />}>
    Go to Gilders...
  </GildButton>
))

const GoToSessions = withRouter(({ history }) => (
  <GildButton
    color='primary'
    variant='contained'
    onClick={() => history.push('sessions')}
    endIcon={<ChevronRightRoundedIcon />}>
    Go to Sessions...
  </GildButton>
))

const abbrev = envAbbrev[env.environment || '']

export default () => (
  <Card>
    <CardContent>
      <img src={`${env.deployedBuildPath}gild-insurance.svg`} />
      <p>Welcome to the Gild Insurance admin</p>
      <p>View or edit a list of all Gilders and their activity</p>
      <GoToMembers />
      <p>View all Gilder sessions</p>
      <GoToSessions />
      <p className="Dashboard__version">v.{packageJson.version}-{abbrev}</p>
    </CardContent>
  </Card>
)
