import React from 'react'
import { 
  List, Datagrid, TextField, UrlField, Filter, BooleanField,
  SimpleShowLayout, TextInput, ArrayField, Show, TopToolbar, SimpleForm
} from 'react-admin'
import Pagination from './Pagination'


const SearchFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="State" source="State" alwaysOn />
    {/* <BooleanInput label="Received Quote" source="quote" alwaysOn />
    <BooleanInput label="Purchased Policy" source="bind" alwaysOn />
    <BooleanInput label="Waiting External Flows" source="external" alwaysOn /> */}
  </Filter>
)


export const FormsList = (props: any) => {
  return (
    <List {...props} pagination={<Pagination />} perPage={25} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="State" />
        <TextField source="Rule" />
        <UrlField source="Form Link" />
      </Datagrid>
    </List>
  )
}