import { env, EnvType } from './env'


export const sanityCheck = () => {
    const DEV = ['https://wp--dev.gilddevel.com/backoffice/#/', '/']
    const PROD = ['https://blog.yourgild.com/backoffice/#/']

    const { basePath = '' }: EnvType = env

    if (PROD.includes(basePath)) {
        return (window.location.origin === 'https://blog.yourgild.com')
    }
    if (DEV.includes(basePath)) {
        return (
            window.location.origin === 'http://localhost:3000' ||
            window.location.origin === 'http://localhost:3001' ||
            window.location.origin === 'http://wp--dev.gilddevel.com' ||
            window.location.origin === 'https://wp--dev.gilddevel.com'
        )
    }
    
    return false
}
