import React from 'react'
import { withRouter } from 'react-router'
import {
  List, Datagrid, DateField, TextField, EmailField, ArrayField, BooleanField,
  Edit, TabbedForm, FormTab, TextInput, BooleanInput, DateInput, TopToolbar, Filter,
  SelectInput
} from 'react-admin'

import { ExportDataButton, ResetPasswordButton, UploadFileButton } from './Buttons'
import { DateTimeField, JSONEventField } from './Fields'
import { env, EnvType } from './env'
import { makeUrl, replaceUrlParams } from './DataProvider/utils'
import { BottomToolBarWithSave } from './BottomToolBar'

const { 
  attachPolicyUrl, attachQuoteUrl, facadeBaseUrl,
  getEventsByUsername, resetCustomerUserPassword
}: EnvType = env


const MemberEditActions = (props: any) => {
  const { data, resetPasswordUrl } = props
  let username, enabled
  if (data) {
    enabled = data['Enabled']
    username = data['Username']
  }
  return (
    <TopToolbar>
      <ResetPasswordButton enabled={enabled} url={replaceUrlParams(resetPasswordUrl, { username })} />
    </TopToolbar>
  )
}

const SearchFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Name" source="name"  />
  </Filter>
)

export const MemberEdit = withRouter((props: any) => {
  const { id, history } = props
  const eventsToCSV = (resp: any) => { 
    return resp['data']['data'].map((r: any) => {
      r['data'] = JSON.stringify(r['data'])
      return r 
    })
  }

  const eventsUrl = makeUrl(facadeBaseUrl, `${getEventsByUsername}?user_id=${id}`)
  const policyUrl = replaceUrlParams(makeUrl(facadeBaseUrl, attachPolicyUrl), { username: id })
  const quoteUrl = replaceUrlParams(makeUrl(facadeBaseUrl, attachQuoteUrl), { username: id })
  const resetCustomerUserPasswordUrl = makeUrl(facadeBaseUrl, resetCustomerUserPassword)

  return (
    <Edit {...props} actions={<MemberEditActions resetPasswordUrl={resetCustomerUserPasswordUrl} />}>
      <TabbedForm toolbar={<BottomToolBarWithSave/>}>
        <FormTab label="summary">
          <BooleanInput source="Enabled" label="Active" />
          <TextInput source="given_name" label="First Name" fullWidth={true} />
          <TextInput source="family_name" label="Last Name" fullWidth={true} />
          <TextInput source="custom:business_name" label="Business Name" fullWidth={true} />
          <BooleanInput source="custom:member_directory" label="Signed up for Member Directory" fullWidth={true} />
          <BooleanInput disabled source="custom:terms" label="Agreed to Terms" fullWidth={true} />
          <TextInput source="email" fullWidth={true} />
          <BooleanInput source="email_verified" label="Email Verified" />
          <TextInput label="Phone Number" source="custom:cell_phone" fullWidth={true} />
          <SelectInput
            allowEmpty={true}
            choices={[{name: 'Email', id: 'email'}, {name: 'Phone', id: 'phone'}]}
            source="custom:preferred_contact"
            label="Preferred Method of Contact"
            resettable={true}
            fullWidth={true}
          />
          <DateInput disabled source="UserCreateDate" label="Gilder Since" />
          <DateInput disabled source="UserLastModifiedDate" label="Last Modified" />
          <TextInput disabled source="id" />
        </FormTab>
        <FormTab label="sessions">
          <ArrayField source="sessions">
            <Datagrid fieldKey="uid" rowClick={(id: string, basePath: string, record: any) => history.push(`/sessions/${record.uid}_${record.session}`)}>
              <TextField source="session" />
              <DateTimeField source="ts" label="Timestamp" />
            </Datagrid>
          </ArrayField>
        </FormTab>
        <FormTab label="events">
          <ExportDataButton getDataFn={eventsToCSV} fields={['etype', 'ts', 'data']} name={`events.${id}`} resourceUrl={eventsUrl} />
          <ArrayField source="events.data">
            <Datagrid>
              <TextField source="etype" label="Event" />
              <DateTimeField source="ts" label="Timestamp" />
              <JSONEventField source="data" />
            </Datagrid>
          </ArrayField>
        </FormTab>
        <FormTab label="quotes">
          <UploadFileButton label="Attach Quote" upload_url={quoteUrl} user_id={id} />
          <ArrayField source="quotes.data">
            <Datagrid rowClick={(id: string, basePath: string, record: any) => { console.log(record); history.push(`/quotes/${record.id}`) }}>
              <TextField source="carrier" label="Carrier" />
              <DateField source="ts" label="Date Issued" />
              <DateField source="expires_on" label="Date Expires" />
              <TextField source="id" label="ID" />
            </Datagrid>
          </ArrayField>
        </FormTab>
        <FormTab label="policies">
          <UploadFileButton label="Attach Policy" upload_url={policyUrl} user_id={id} />
          <ArrayField source="policies.data" fieldKey="id">
            <Datagrid rowClick={(id: string, basePath: string, record: any) => history.push(`/policies/${record.id}`)}>
              <TextField source="carrier" label="Carrier" />
              <DateField source="ts" label="Date Issued" />
              <DateField source="expires_on" label="Date Expires" />
              <TextField source="id" label="ID" />
            </Datagrid>
          </ArrayField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
});

export const MemberList = (props: any) => (
  <List {...props} filters={<SearchFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="custom:business_name" label="Business Name" />
      <EmailField source="email" />
      <BooleanField source="email_verified" />
      <DateField source="UserCreateDate" label="User Since" />
      <TextField source="id" />
    </Datagrid>
  </List>
)
