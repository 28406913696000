
interface ReducerProps {
    type: string
    payload: any
}

export const SESSION_NOTIFICATIONS = 'SESSION_NOTIFICATIONS'

export const notificationsReducer = (previousState = 0, { type, payload }: ReducerProps) => {
    if (type === SESSION_NOTIFICATIONS) {
        return payload.count;
    }
    return previousState;
}
