import React from 'react'
import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'
import {
  withAuthenticator, SignIn, ConfirmSignIn, VerifyContact,
  ForgotPassword, RequireNewPassword, Loading, SignOut
} from 'aws-amplify-react'
import '@aws-amplify/ui/dist/style.css'

import BackOffice from './BackOffice'
import { env } from './env'


Auth.configure({
  region: 'us-east-2',
  userPoolId: env.cognitoUserPoolId,
  userPoolWebClientId: env.cognitoClientId,
  mandatorySignIn: true,
  identityPoolId: env.backofficeIdentityPoolId
})

Storage.configure({
  region: 'us-east-2',
  bucket: env.documentStorageBucket,
  level: 'public', 
  identityPoolId: env.backofficeIdentityPoolId 
})


const App = () => {
  return (
    <div className="App">
      <BackOffice />
    </div>
  );
}

export default withAuthenticator(App, true, [
  <SignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <ForgotPassword />,
  <RequireNewPassword />,
  <Loading />
]);
