import axios from 'axios'
import { env, EnvType } from '../env'
import { handleSortAndFilter, Headers, makeUrl, Params, toBoolean } from './utils'
import { wcForms } from './static/forms'

const {
  facadeBaseUrl, getAllUsers, getAllMembers, getAllPolicies, getAllQuotes, getAllSessions
}: EnvType = env

const emptyList: ListResult = {
  data: [],
  total: 0
}

interface ListResult {
  data: any[],
  total: number
}


export const getList = (headers: Headers) => 
  (resource: string, params: Params) => {
    console.log('getList', resource)
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const start = (page - 1) * perPage;
    const end = (page * perPage) - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      page,
      perPage,
      filter: JSON.stringify(params.filter)
    };

    let handleResp = (resp: any) => {
      console.log(resp)
      return emptyList
    }
    let url = '';

    try {
      switch (resource) {
        case 'employees': {
          url = makeUrl(facadeBaseUrl, getAllUsers, query)
          handleResp = (resp) => {
            const { data } = resp['data']
            const structuredData = data['Users'].map((u: any) => { 
              u.id = u.Username
              u['email_verified'] = toBoolean(u['email_verified'])
              if (u['phone_number_verified']) {
                u['phone_number_verified'] = toBoolean(u['phone_number_verified'])
              }
              return u
            })

            return {
              data: handleSortAndFilter(structuredData, query),
              total: data['Users'].length
            }
          }
          break
        }
        // case 'events': {
        //   url = makeUrl(facadeBaseUrl, getAllTrailEvents, query)
        //   handleResp = (resp) => {
        //     const { data } = resp['data']
        //     return {
        //       data: data.map((u: any) => { u.id = u.Username; return u; }),
        //       total: data.length
        //     }
        //   }
        //   break
        // }
        case 'gilders': {
          url = makeUrl(facadeBaseUrl, getAllMembers, query)
          handleResp = (resp) => {
            const { data } = resp['data']
            const structuredData = data['Users'].map((u: any) => {
              u.id = u.Username
              u.email_verified = u.email_verified === 'true' ? true : false
              return u
            })

            return {
              data: handleSortAndFilter(structuredData, query),
              total: data['Users'].length
            }
          }
          break
        }
        case 'policies':
          url = makeUrl(facadeBaseUrl, getAllPolicies, query)
          handleResp = (resp) => {
            const { data } = resp['data']['data']

            return {
              data: handleSortAndFilter(data, query),
              total: data.length
            }
          }
          break
        case 'quotes':
          url = makeUrl(facadeBaseUrl, getAllQuotes, query)
          handleResp = (resp) => {
            const { data } = resp['data']['data']
            console.log('quotes.respList', resp)
            return {
              data: handleSortAndFilter(data, query),
              total: data.length
            }
          }
          break
        case 'sessions': {
          url = makeUrl(facadeBaseUrl, getAllSessions, query)
          handleResp = (resp) => {
            const { data } = resp['data']
            const structuredData = data.map((s: any) => {
              s.id = s.uid !== '__anon__' ? `${s.uid}_${s.session}` : `_${s.session}`  // TODO look into weird bug with anon session names and missing _
              if (s.answer) {
                s.answer = `${s.answer.slice(0, 1).toUpperCase()}${s.answer.slice(1)}`
              }
              return s
            })
            return {
              data: handleSortAndFilter(structuredData, query),
              total: data.length
            }
          }
          break
        }
        case 'partners': {
          const partnerQuery = {
            sort: JSON.stringify([field, order]),
            page,
            perPage,
            filter: JSON.stringify(Object.assign({}, params.filter, {partner: true}))
          };
          url = makeUrl(facadeBaseUrl, getAllSessions, partnerQuery)
          handleResp = (resp) => {
            const { data } = resp['data']
            const structuredData = data.map((s: any) => {
              s.id = s.uid !== '__anon__' ? `${s.uid}_${s.session}` : `_${s.session}`
              if (s.answer) {
                s.answer = `${s.answer.slice(0, 1).toUpperCase()}${s.answer.slice(1)}`
              }
              return s
            })
            return {
              data: handleSortAndFilter(structuredData, query),
              total: data.length
            }
          }
          break
        }
        case 'forms': {
          const data = [...wcForms]
          url = makeUrl(facadeBaseUrl, getAllSessions, query)

          handleResp = (resp) => {
            return {
              data: handleSortAndFilter(data.map((d, idx) => { d['id'] = idx; return d }), query),
              total: data.length
            }
          }
          break
        }
      }
    } catch(e) {
      console.error(e)
    }

    if (!url) {
      return new Promise(() => {}).then(handleResp)
    }
    return axios.get(url, { headers })
      .then(handleResp)
      .catch(res => {
        console.error('Error in axios request')
        console.log(res)
      })
  }