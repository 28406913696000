import React from 'react'
import {
  List, Datagrid, DateField, TextField, TopToolbar,
  Edit, TextInput, DateInput, SimpleForm, NumberInput
} from 'react-admin'
import { DownloadFileButton, GoToUser } from './Buttons'
import { CarrierField } from './Fields'
import { env, EnvType } from './env'
import { makeUrl, replaceUrlParams } from './DataProvider/utils'
import { withRouter } from 'react-router'

const { 
  facadeBaseUrl, downloadQuoteUrl
}: EnvType = env


const QuoteEditActions = withRouter((props: any) => {
  const { data, history } = props
  const downloadUrl = replaceUrlParams(makeUrl(facadeBaseUrl, downloadQuoteUrl), { file_id: data?.id })
  return (
    <TopToolbar>
      <GoToUser history={history} uid={data?.uid} />
      <DownloadFileButton url={downloadUrl} />
    </TopToolbar>
  )
})

export const QuoteEdit = (props: any) => (
  <Edit {...props} actions={<QuoteEditActions />}>
    <SimpleForm>
      <TextInput disabled source="orig_filename" label="Filename" fullWidth={true} />
      <TextInput disabled source="key" label="Encrypted Key" fullWidth={true} />
      <NumberInput disabled source="policy_number" />
      <NumberInput disabled source="yearly_premium" label="Yearly Premium in Dollars" />
      <TextInput disabled source="product" />
      <TextInput disabled source="carrier" />
      <TextInput disabled source="completed" label="Quote Accepted" />
      <DateInput disabled source="completed_ts" label="Accepted On" />
      <DateInput disabled source="ts" label="Created On" /> 
      <DateInput disabled source="expires_on" /> 
      <TextInput disabled source="session" fullWidth={true} />
      <TextInput disabled source="uid" fullWidth={true} />
      <TextInput disabled source="id" fullWidth={true} />
    </SimpleForm>
  </Edit>
)

export const QuotesList = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="business_name" label="Business" />
      <TextField source="product" />
      <CarrierField source="carrier" />
      <DateField source="ts" label="Created On" />
      <DateField source="expires_on" label="Expires On" />
      <TextField source="uid" label="User" />
      <TextField source="id" />
    </Datagrid>
  </List>
)
