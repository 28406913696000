import React from 'react'
import {
  List, Datagrid, DateField, TextField, TopToolbar,
  Edit, TextInput, DateInput, SimpleForm
} from 'react-admin'
import { DownloadFileButton, GoToUser } from './Buttons'
import { CarrierField } from './Fields'
import { env, EnvType } from './env'
import { makeUrl, replaceUrlParams } from './DataProvider/utils'
import { withRouter } from 'react-router'

const { 
  facadeBaseUrl, downloadPolicyUrl
}: EnvType = env


const PolicyEditActions = withRouter((props: any) => {
  const { data, history } = props
  const downloadUrl = replaceUrlParams(makeUrl(facadeBaseUrl, downloadPolicyUrl), { file_id: data?.id })
  return (
    <TopToolbar>
      <GoToUser history={history} uid={data?.uid} />
      <DownloadFileButton url={downloadUrl} />
    </TopToolbar>
  )
})

export const PolicyEdit = (props: any) => (
  <Edit {...props} actions={<PolicyEditActions />}>
    <SimpleForm>
      <TextInput disabled source="orig_filename" label="Filename" fullWidth={true} />
      <TextInput disabled source="key" label="Encrypted Filename" fullWidth={true} />
      <TextInput disabled source="product" />
      <TextInput disabled source="carrier" />
      <TextInput disabled source="quote_id" fullWidth={true} />
      <DateInput disabled label="Created On" source="ts" />
      <DateInput disabled source="starts_on" />
      <DateInput disabled source="expires_on" />
      <TextInput disabled source="session" fullWidth={true} />
      <TextInput disabled source="uid" fullWidth={true} />
      <TextInput disabled source="id" fullWidth={true} />
    </SimpleForm>
  </Edit>
)

export const PoliciesList = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="key" />
      <CarrierField source="carrier" />
      <DateField source="ts" label="Created On" />
      <DateField source="expires_on" label="Expires On" />
      <TextField source="uid" label="User" />
      <TextField source="session" />
      <TextField source="id" />
    </Datagrid>
  </List>
)
