import React, { useEffect, useState } from 'react'
import { Admin, Resource, ListGuesser } from 'react-admin'
import Auth from '@aws-amplify/auth'

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import UserIcon from '@material-ui/icons/Group'
import DescriptionIcon from '@material-ui/icons/Description'
import ListAltIcon from '@material-ui/icons/ListAlt'
import BusinessIcon from '@material-ui/icons/Business'

import authProvider from './auth'
import Dashboard from './Dashboard'
import gildDataProvider from './DataProvider'
import { env } from './env'
import Layout from './Layout'
// import { nextToken } from './reducer'
import { notificationsReducer } from './reducers'
import theme from './theme'
import { AgentCreate, AgentEdit, AgentList } from './Agents'
import { FormsList } from './Forms'
import { PartnerEdit, PartnersList } from './Partners'
import { PolicyEdit, PoliciesList } from './Policies'
import { QuoteEdit, QuotesList } from './Quotes'
import { SessionEdit, SessionsList } from './Sessions'
import { MemberEdit, MemberList } from './Users'
import { sanityCheck } from './utils'


const BackOffice = () => {
  const [token, setToken] = useState()
  const authenticate = () => Auth.currentSession().then((resp: any) => {
    const accessJwt = resp.getAccessToken().getJwtToken()
    setToken(accessJwt)
  })
  authenticate()

  useEffect(() => {
    const interval = setInterval(() => {
      authenticate()
    }, 1000 * 60) // refresh every 15 minutes (this prevents it from expiring if the page is never reloaded)
    return () => clearInterval(interval);
  }, []);

  if (token) {
    const dataProvider = gildDataProvider(token)
    return (
      <>
        <div className={`Admin-wrapper${sanityCheck() ? '' : ' --insane'}`}>
          <Admin
            appLayout={Layout}
            authProvider={authProvider}
            customReducers={{ notifications: notificationsReducer }}
            dashboard={Dashboard} 
            dataProvider={dataProvider}
            theme={theme}>
            {(permissions: string) => [
              env.adminUserGroup && permissions.indexOf(env.adminUserGroup) >= 0
                ? <Resource create={AgentCreate} name="employees" options={{ label: 'Employees' }} list={AgentList} edit={AgentEdit} icon={RecordVoiceOverIcon} token={token} />
                  : null,
              <Resource name="gilders" options={{ label: 'Gilders' }} list={MemberList} edit={MemberEdit} icon={UserIcon} token={token} />,
              <Resource name="policies" options={{ label: 'Policies' }} list={PoliciesList} edit={PolicyEdit} icon={DescriptionIcon} />,
              <Resource name="quotes" options={{ label: 'Quotes' }}  list={QuotesList} edit={QuoteEdit} icon={ListAltIcon} />,
              <Resource name="sessions" list={SessionsList} edit={SessionEdit} icon={ShoppingCartIcon} />,
              <Resource name="partners" options={{ label: 'Partners' }} list={PartnersList} edit={PartnerEdit} icon={BusinessIcon} />,
              <Resource name="forms" options={{ label: 'Forms' }} list={FormsList} icon={DescriptionIcon} />
            ]}
          </Admin>
        </div>
        <img className="gild-city" src={`${env.deployedBuildPath}front-city.png`} />
      </>
    )
  }
  return <></>
}

export default BackOffice