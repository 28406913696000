import { stringify } from 'query-string'


export interface CreateParams {
  data?: any
}

export interface Headers {
  Authorization?: string
  'Content-Type'?: string
}

export const handleSortAndFilter = (data: any[], query: any): any[] => {
  const { sort, filter } = query
  // In-browser sorting
  const [field, dir] = JSON.parse(sort)
  switch (field) {
    case 'created_on':
    case 'expires_on':
    case 'UserCreateDate':
      data.sort((a, b) => new Date(a[field]) > new Date(b[field]) ? 1 :  -1)
      break
    default:
      data.sort((a, b) => a[field] > b[field] ? 1 : -1)
  }

  return (dir == 'DESC') ? data.reverse() : data
}

export const makeHeaders = (token?: string) => ({
  Authorization: `bearer ${token}`,
})

export const makeUrl = (facadeBaseUrl: string = '', url: string = '', query: any = {}) => {
  if (url.indexOf('?') >= 0) {
    return `${facadeBaseUrl}/${url}${stringify(query)}`;  
  }
  return `${facadeBaseUrl}/${url}?${stringify(query)}`;
}

export interface Params {
  id?: string,
  filter: any,
  pagination: any,
  sort: any
  previousData?: any
}

export interface UpdateParams {
  id: string,
  data: any,
  previousData?: any
}

export const replaceUrlParams = (url: string = '', params: any) => {
  let newUrl = url
  Object.keys(params).forEach(key => {
    const value = params[key]
    newUrl = newUrl.replace(`<${key}>`, value)
  })
  return newUrl
}

export const toBoolean = (str: string) => {
  const valid = ['true', 'True']
  if (valid.indexOf(str) >= 0) {
    return true
  }
  return false
}
