interface Form {
  id?: number
  State: string
  Rule: string
  'Form Link': string
  Notes: string
}


export const wcForms: Form[] = [
    {
      "State": "Alabama",
      "Rule": "Exclusion OR Inclusion Members/Officers/Sole Proprietors/Partnerships",
      "Form Link": "https://www.workerscompensationshop.com/docs/Alabama/al_wc_corporate_exclusions-inclusions.pdf",
      "Notes": ""
    },
    {
      "State": "Alabama",
      "Rule": "Corporation Officer Exclusion",
      "Form Link": "https://www.workerscompensationshop.com/docs/Alabama/AL_exclusion_form.pdf",
      "Notes": ""
    },
    {
      "State": "Alaska",
      "Rule": "Executive Officer Waiver - Owners and Officers of corporations should complete this form to exclude themselves",
      "Form Link": "https://www.workerscompensationshop.com/docs/Alaska/ak-wc-executive-officer-waiver.pdf",
      "Notes": ""
    },
    {
      "State": "Alaska",
      "Rule": "Exclusion Form sole-proprietor, partnership with no employees or an sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Alaska/ak-wc-exemption-form.pdf",
      "Notes": ""
    },
    {
      "State": "California",
      "Rule": "Exclude Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/California/ca-exclusion-form.doc",
      "Notes": ""
    },
    {
      "State": "California",
      "Rule": "Exemption form for California Contractors State Licensing Board. Contractors must file proof of coverage and complete the exemption form",
      "Form Link": "https://www.workerscompensationshop.com/docs/California/ca-wc-exemption-from-wc.pdf",
      "Notes": ""
    },
    {
      "State": "Colorado",
      "Rule": "Exclude Individuals, Sole Proprietors, Partners and Corporate Officers",
      "Form Link": "https://www.workerscompensationshop.com/docs/Colorado/CO_officer_rejection_of_coverage_form.pdf",
      "Notes": ""
    },
    {
      "State": "Connecticut",
      "Rule": "Exclude sole-proprietor or with no employees",
      "Form Link": "https://www.workerscompensationshop.com/docs/Connecticut/ct-wc-election-of-coverage-by-sole-proprieter-or-single-llc.pdf",
      "Notes": ""
    },
    {
      "State": "Connecticut",
      "Rule": "Exclude Coverage Election by Employee who is an Officer of a Corporation, Manager of an LLC, or Member of a Multiple-Member LLC",
      "Form Link": "https://www.workerscompensationshop.com/docs/Connecticut/ct-wc-officer-rejection-of-coverage-form.pdf",
      "Notes": ""
    },
    {
      "State": "Connecticut",
      "Rule": "Exclude partnership or with no employees",
      "Form Link": "https://www.workerscompensationshop.com/docs/Connecticut/ct-wc-election-of-coverage-by-partnership.pdf",
      "Notes": ""
    },
    {
      "State": "Delaware",
      "Rule": "Exclude corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Delaware/de-wc-officer-exclusion-form.pdf",
      "Notes": ""
    },
    {
      "State": "Delaware",
      "Rule": "Inclusion and exclusion all others",
      "Form Link": "https://www.workerscompensationshop.com/docs/Delaware/de-wc-election-of-coverage.pdf",
      "Notes": ""
    },
    {
      "State": "Florida",
      "Rule": "Inclusion sole proprietor or partnership",
      "Form Link": "https://www.workerscompensationshop.com/docs/Florida/FL_election_of_coverage_form_individual_partner.pdf",
      "Notes": ""
    },
    {
      "State": "Florida",
      "Rule": "Exclusion corporation or llc",
      "Form Link": "https://www.myfloridacfo.com/Division/wc/Employer/Exemptions/",
      "Notes": ""
    },
    {
      "State": "Idaho",
      "Rule": "Exclude Sole proprietors",
      "Form Link": "https://www.workerscompensationshop.com/docs/Idaho/id-wc-declaration-of-exemption.pdf",
      "Notes": ""
    },
    {
      "State": "Indiana",
      "Rule": "Exclude from coverage sole-proprietor, partnership with no employees or an sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Indiana/in-officer-exclusion-form.doc",
      "Notes": ""
    },
    {
      "State": "Indiana",
      "Rule": "Exempt from coverage sole-proprietor, partnership with no employees or an sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Indiana/in-wc-application-for-exemption.pdf",
      "Notes": ""
    },
    {
      "State": "Iowa",
      "Rule": "Exclude Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/Iowa/ia-wc-office-exclusion-form.pdf",
      "Notes": ""
    },
    {
      "State": "Iowa",
      "Rule": "Exempt sole-proprietor, partnership with no employees or a sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Iowa/http___www.IA_officer_rejection_form.pdf",
      "Notes": ""
    },
    {
      "State": "Kansas",
      "Rule": "Exclusion Election not to accept coverage under Kansas Workers' Compensation Act by an employee who ones 10% or more Corporate stock of corporate employer",
      "Form Link": "https://www.workerscompensationshop.com/docs/Kansas/ks-election-not-to-accept-coverage.pdf",
      "Notes": ""
    },
    {
      "State": "Kansas",
      "Rule": "Elect coverage of a non-compensated officer, trustee of a nonprofit corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Kansas/ks-wc-election-of-a-noncompensated-officer.pdf",
      "Notes": ""
    },
    {
      "State": "Kansas",
      "Rule": "Elect coverage Self-employed individuals, partners, and LLC Members",
      "Form Link": "https://www.workerscompensationshop.com/docs/Kansas/ks-election-of-individual.pdf",
      "Notes": ""
    },
    {
      "State": "Kansas",
      "Rule": "Elect coverage employer has less than $20,000 Payroll or is an agricultural pursuit",
      "Form Link": "https://www.workerscompensationshop.com/docs/Kansas/ks-wc-election-of-employer-to-cover-employess.pdf",
      "Notes": ""
    },
    {
      "State": "Kansas",
      "Rule": "Elect to cover volunteers",
      "Form Link": "https://www.workerscompensationshop.com/docs/Kansas/ks-wc-election-of-employer-to-cover-volunteers.pdf",
      "Notes": ""
    },
    
    {
      "State": "Maine",
      "Rule": "Exempt sole-proprietor, partnership or an officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Maine/me-officer-exclusion-form.doc",
      "Notes": ""
    },
    {
      "State": "Maine",
      "Rule": "Exclude from coverage",
      "Form Link": "https://www.maine.gov/wcb/forms/WCB-2C.pdf",
      "Notes": ""
    },
    {
      "State": "Maryland",
      "Rule": "Exclusion form officer and members",
      "Form Link": "https://www.workerscompensationshop.com/docs/Maryland/md-wc-exclusion-form.pdf",
      "Notes": ""
    },
    {
      "State": "Maryland",
      "Rule": "Exclusion form sole proprietors and partners",
      "Form Link": "https://www.workerscompensationshop.com/docs/Maryland/MD_individual_inclusion_form.pdf",
      "Notes": ""
    },
    {
      "State": "Massachusetts",
      "Rule": "Exclusion form Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/Massachusetts/MA_officer_exclusion_form.pdf",
      "Notes": ""
    },
    {
      "State": "Massachusetts",
      "Rule": "Exemption form sole-proprietor, partnership with no employees or an sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Massachusetts/ma-wc-application-for-exemption-of-officers.pdf",
      "Notes": ""
    },
    {
      "State": "Michigan",
      "Rule": "Exclusion Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/Michigan/mi-wc-exclusion-form.pdf",
      "Notes": ""
    },
    {
      "State": "Missouri",
      "Rule": "Exclude Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/Missouri/mo-wc-officer-exclusion-form.pdf",
      "Notes": ""
    },
    {
      "State": "Missouri",
      "Rule": "Exempt sole-proprietor or partnership with no employees",
      "Form Link": "https://www.workerscompensationshop.com/docs/Missouri/mo-wc-individual-partner-exemption-form.pdf",
      "Notes": ""
    },
    {
      "State": "Nevada",
      "Rule": "Sole proprietor include/exclude from coverage",
      "Form Link": "https://www.workerscompensationshop.com/docs/Nevada/NV_individual_election_form.pdf",
      "Notes": ""
    },
    {
      "State": "New Hampshire",
      "Rule": "Exclusion from coverage Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/New-Hampshire/nh-officer-exclusion-form.doc",
      "Notes": ""
    },
    {
      "State": "New Hampshire",
      "Rule": "Exempt from coverage sole-proprietor, partnership with no employees or an sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/New-Hampshire/New%20Hampshire%20Exclusion.pdf",
      "Notes": ""
    },
    {
      "State": "New Jersey",
      "Rule": "Elect or exempt from coverage",
      "Form Link": "https://www.nj.gov/labor/workerscompensation/tools-resources/forms-publications/",
      "Notes": ""
    },
    {
      "State": "New Mexico",
      "Rule": "Exclude Individuals, Sole Proprietors, Partners and Corporate Officers",
      "Form Link": "https://workerscomp.nm.gov/sites/default/files/documents/forms/executive_employee_affirmative.pdf",
      "Notes": ""
    },
    {
      "State": "New Mexico",
      "Rule": "Elect coverage",
      "Form Link": "https://workerscomp.nm.gov/sites/default/files/documents/forms/election_to_be_subject.pdf",
      "Notes": ""
    },
    // {
    //   "State": "New York",
    //   "Rule": "",
    //   "Form Link": "",
    //   "Notes": "N/A at this time"
    // },
    {
      "State": "Pennsylvania",
      "Rule": "Exemption from coverage for contractors, religious organizations, and executive  corporate employees",
      "Form Link": "https://www.workerscompensationshop.com/docs/Pennsylvania/pa-wc-affidavit-of-exemption.pdf",
      "Notes": ""
    },
    {
      "State": "Pennsylvania",
      "Rule": "Exception for executive officer",
      "Form Link": "https://www.workerscompensationshop.com/docs/Pennsylvania/PA_officer_exemption_form.pdf",
      "Notes": ""
    },
    {
      "State": "Rhode Island",
      "Rule": "Exclusion Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/Rhode-Island/ri-officer-exclusion-form.doc",
      "Notes": ""
    },
    {
      "State": "Rhode Island",
      "Rule": "Inclusion Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/Rhode-Island/RI_officer_inclusion_form.pdf",
      "Notes": ""
    },
    {
      "State": "South Carolina",
      "Rule": "Exclusion Owners and Officers of corporations",
      "Form Link": "https://wcc.sc.gov/employers",
      "Notes": ""
    },
    {
      "State": "South Carolina",
      "Rule": "Exclusion Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/South-Carolina/sc-wc-corp-officer-notice-of-rejection.pdf",
      "Notes": ""
    },
    {
      "State": "South Carolina",
      "Rule": "Exempt sole-proprietor, partnership with no employees or an sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/South-Carolina/sc-wc-employers-election-to-withdrawl-wc.pdf",
      "Notes": ""
    },
    {
      "State": "Tennessee",
      "Rule": "Include employee",
      "Form Link": "https://www.workerscompensationshop.com/docs/Tennessee/tn-exempt-employer-form-i08.pdf",
      "Notes": ""
    },
    {
      "State": "Tennessee",
      "Rule": "Withdraw exemption from coverage",
      "Form Link": "https://www.workerscompensationshop.com/docs/Tennessee/tn-owner-exempt-withdrawal-form-i09.pdf",
      "Notes": ""
    },
    {
      "State": "Tennessee",
      "Rule": "Exclude Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/Tennessee/TN_exclusion_form_corporation.pdf",
      "Notes": ""
    },
    {
      "State": "Tennessee",
      "Rule": "Exempt sole-proprietor, partnership with no employees or a sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Tennessee/TN_inclusion_form_individual_partner_llc.pdf",
      "Notes": ""
    },
    {
      "State": "Tennessee",
      "Rule": "Withdraw exemption from coverage sole-proprietor or partnership",
      "Form Link": "https://www.workerscompensationshop.com/docs/Tennessee/tn-owner-inclusion-withdrawal-form-i05.pdf",
      "Notes": ""
    },
    {
      "State": "Vermont",
      "Rule": "Exclude Owners and Officers of corporations",
      "Form Link": "https://www.workerscompensationshop.com/docs/Vermont/vt-wc-application-for-exclusion-from-wc-coverage.pdf",
      "Notes": ""
    },
    {
      "State": "Wisconsin",
      "Rule": "Exclude Owners and Officers of corporations",
      "Form Link": "https://dwd.wisconsin.gov/dwd/forms/ui/pdf/uct-8055.pdf",
      "Notes": ""
    },
    {
      "State": "Wisconsin",
      "Rule": "Exclude Stockholders",
      "Form Link": "https://www.workerscompensationshop.com/docs/Wisconsin/wi-wc-supplementary-non-election-form.pdf",
      "Notes": ""
    },
    {
      "State": "Wisconsin",
      "Rule": "Exempt sole-proprietor, partnership with no employees or an sole officer of a corporation",
      "Form Link": "https://www.workerscompensationshop.com/docs/Wisconsin/wi-wc-employee-coverage-exceptions.pdf",
      "Notes": ""
    }
]