import axios from 'axios'
import { env, EnvType } from '../env'
import { Headers, Params, replaceUrlParams } from './utils'

const { facadeBaseUrl, getUser }: EnvType = env


export const del = (headers: Headers) => 
  (resource: string, params: Params) => {
    console.log('delete params:', params)
    const { id } = params
    
    let handleResp = (resp: any) => {
      console.log(resp)
      return resp
    }
    let url = ''
    let data = {}

    switch (resource) {
      case 'employees': {
        url = replaceUrlParams(`${facadeBaseUrl}/${getUser}`, {username: id})
        console.log('EMPLOYEES DELETE', url, headers)
        return axios({
            method: 'delete', 
            url, 
            headers
        })
          .then(handleResp)
          .catch(res => console.error(res))
      }
      case 'policies': {
        /// Delete on policies ends up flagging it as soft_delete in the db. This is more of a patch than a delete.
        url = replaceUrlParams(`${facadeBaseUrl}/admin/policy/${id}/soft_delete`, {policyId: id})
        data = {policyUID: params.previousData.uid, timestamp: params.previousData.ts}
        console.log('POLICIES DELETE', url, headers)
        return axios({
            method: 'patch', 
            url, 
            headers, 
            data
        })
          .then(handleResp)
          .catch(res => console.error(res))
      }
      case 'quotes': {
        /// Delete on quotes ends up flagging it as soft_delete in the db. This is more of a patch than a delete.
        url = replaceUrlParams(`${facadeBaseUrl}/admin/quote/${id}/soft_delete`, {policyId: id})
        data = {quoteUID: params.previousData.uid, timestamp: params.previousData.ts}
        console.log('QUOTES DELETE', url, headers)
        return axios({
            method: 'patch', 
            url, 
            headers, 
            data
        })
          .then(handleResp)
          .catch(res => console.error(res))
      }
    }
  }
